<div class="row">
    <div class="spacer"></div>
    <div class="column">

        <br>
        <button mat-button (click)="social('openstreetmap')">Login via OpenStreetMap</button>
        <button mat-button (click)="social('google')">Login via Google</button>
        <button mat-button (click)="social('github')">Login via GitHub</button>
    </div>
    <div class="spacer"></div>
</div>